@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
}

.dashboard_content_section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.total_dash_content h4 {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 400;
}

.total_dash_content p span {
  color: #fff !important;
  font-size: 32px !important;
  font-weight: 600;
}

.total_dash_content p {
  color: #fff !important;
  font-size: 14px !important;
}

.total_dash_card {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #141414;
  padding: 20px 32px;
  border-radius: 12px;
  border: solid 1px #434343;
  margin-bottom: 20px;
  min-height: 152px;
}

.relative {
  position: relative !important;
}

.username {
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 400;
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #434343;
  margin-bottom: 20px;
}

.ranking h4 {
  font-size: 30px !important;
  color: #fff !important;
  margin: 0px !important;
  font-weight: 500;
  margin-bottom: 10px !important;
  text-align: center;
}

.ranking h5 {
  font-size: 20px !important;
  margin: 0px !important;

  margin-bottom: 10px !important;
  color: #fff !important;
  font-weight: 300;
  text-align: center;
}

.ranking .total_dash_card1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #141414;
  padding: 20px;
  border-radius: 12px;
  border: solid 1px #434343;
  margin-bottom: 20px;
  min-height: 230px;
}

.ranking .total_dash_card1 .total_balance {
  width: 100px !important;
}

.left_referral_link_dash h3 {
  color: #fff !important;
  font-size: 16px !important;
}

.left_referral_link input {
  background: transparent;
  color: #a1a1a1;
  width: 100%;
  text-overflow: ellipsis;
  padding: 0px 12px;
  border: solid 1px #9747ff;
  border-radius: 8px 0px 0 8px;
  font-size: 14px;
}

.left_referral_link {
  display: flex;
  justify-content: space-between;
}

.dashboard_links {
  display: flex;
  text-align: center;
  padding: 20px 20px 20px 32px;
  border-radius: 12px;
  background-color: #141414;
  border: solid 1px #434343;
  margin: 0px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.left_referral_link i {
  background-color: #9747ff;
  color: #fff !important;
  font-size: 18px !important;
  padding: 12px 15px;
  border-radius: 0px 8px 8px 0px;
}

.referral_title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 400;
  margin-bottom: 18px;
}

.wxdl_dashboard.table thead tr th {
  background: #1c1c1c !important;
  color: #a4a4a4;
  font-size: 13px;
  font-weight: 400;
  border: none;
  text-align: center;
  text-wrap: nowrap;
  padding: 18px 12px;
}

.wxdl_dashboard.table tbody tr th,
.wxdl_dashboard.table tbody tr td {
  background: #141414;
  color: #fff;
  text-align: center;
  padding: 15px;
  text-wrap: nowrap;
}

.wxdl_dashboard.table tbody tr {
  border-color: #2b2b2b;
}

.table_pagination {
  display: flex !important;
  justify-content: center !important;
}

.table_pagination ul li button {
  color: #fff !important;
}

th.start {
  border-radius: 6px 0 0 6px !important;
}

th.end {
  border-radius: 0px 6px 6px 0px !important;
}

.dashboard_table_content {
  padding: 24px 24px 42.5px;
  border-radius: 12px;
  position: relative;
  background-color: #141414;
  border: solid 1px rgba(255, 255, 255, 0.2);
}

.main-header_buttons {
  align-items: center;
}

.total_dash_card img {
  width: 60px;
}

.total_dash_card.h1s {
  background: url("./images/total_asset_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
}

.deposit_btn button {
  padding: 10px 16px;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  background-color: #761fe3;
  gap: 12px;
}

.download_icon_asset {
  width: 20px;
}

/*  */
/* .deposit_main {
  padding: 20px;
} */

.inner_header {
  margin-bottom: 20px;
}

.wxdl_deposit_content {
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}

.ui.inline.dropdown .menu {
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  background: #000;
}
.staking_banner_content input {
  /* width: 100%; */
  color: #fff;
  padding: 16px;
  border-radius: 6px;
  border: dashed 0.5px rgb(135, 128, 128);
  background-color: #141414 !important;
}
.ui.inline.dropdown .menu .item {
  display: flex;
  align-items: center;
}

.ui.inline.dropdown .menu .item img.ui.avatar.image {
  margin-right: 10px;
}

/* .deposit_main {
  padding-top: 100px;
} */
.wxdl_deposit {
  margin: 0 auto;
  padding: 24px 16px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #141414;
  margin-top: 40px;
  margin-bottom: 60px;
}

.wxdl_deposit_content h3 {
  color: #d0d0d0 !important;
  font-size: 14px !important;
  font-weight: 400;
  margin-bottom: 0;
}

.ui.visible.inline.dropdown {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.your_pay_amt_text .ui.inline.dropdown .menu {
  position: absolute;
  left: -50px;
}

.ui.inline.dropdown {
  cursor: pointer;
  display: inline-block;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #202020;
}

.ui.inline.dropdown > .text {
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
}

.ui.dropdown > .dropdown.icon:before {
  color: #fff;
}

#network_dropdown {
  cursor: pointer;
  display: inline-block;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #202020;
  width: 100%;
}

.remainder_content h3 {
  color: #d0d0d0 !important;
  font-size: 14px !important;
  font-weight: 400;
}

.remainder_content {
  padding: 10px;
}

.min_dep_text {
  display: flex;
  justify-content: space-between;
}

.min_dep_text p {
  color: #a1a1a1 !important;
  font-size: 13px !important;
}

.net_address_qr h3 {
  color: #d0d0d0 !important;
  font-size: 14px !important;
  font-weight: 400;
  margin-top: -20px;
}

.net_address_qr {
  padding: 10px;
}

.net_address_qr img {
  display: flex;
  margin: 0 auto;
}

.net_address_qr p {
  text-align: center;
  color: #a1a1a1 !important;
  font-size: 13px !important;
  margin-top: 10px;
}

.currency_copy_content {
  margin: 0 8px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #202020;
  color: #fff;
}

.currency_copy_content input {
  padding: 0;
  background: transparent;
  color: #797979;
  cursor: pointer;
  padding: 18px 5px;
  width: 100%;
}

.ri-file-copy-line {
  color: #9747ff;
}

.deposit_history_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deposit_history_content p {
  color: #9747ff !important;
  font-size: 16px !important;
}

.qr_bg {
  box-shadow: 0 4px 13px 0 #9747ff;
  margin-bottom: 20px !important;
}

.text-green {
  color: #1ec18f !important;
}

.usdt_currency_items {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 5px;
}

.usdt_currency_items p {
  color: #fff;
  font-size: 14px;
}

.withdraw_input_address {
  cursor: pointer;
  color: #797979;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #202020;
  width: 100%;
}

.table_overflow {
  overflow: hidden;
  overflow-x: auto;
}

.wxdl_dashboard.three.table tbody tr th,
.wxdl_dashboard.three.table tbody tr td {
  width: 33.33%;
  border: none !important;
}

.witthdraw_amt_title {
  color: #d0d0d0 !important;
  font-size: 14px !important;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.withdrawal_button button {
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #761fe3;
  padding: 18px 20px;
  width: 100%;
  color: #fff;
  margin-top: 0;
}

/* Tree View */
.tree {
  width: 100%;
  height: auto;
  transform: scale(0.9);
  text-align: center;
}

.tree ul {
  padding-top: 20px;
  position: relative;
  display: flex;

  transition: 0.5s;
}

.tree li {
  /* display: inline-table; */
  display: table-cell;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 25px 10px 0px;
  transition: 0.5s;
  width: 100%;
}

.tree li::before,
.tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 51%;
  height: 25px;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li:only-child::after,
.tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #ccc;
  width: 0;
  height: 20px;
}

.tree li a {
  width: 100% !important;
  /* border: 1px solid #434343; */
  padding: 10px;
  display: inline-grid;
  justify-content: center;
  border-radius: 5px;
  text-decoration-line: none;
  border-radius: 5px;
  transition: 0.5s;
  width: 150px;
  flex-direction: column;
  border-radius: 15px;
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
  margin: 0px 19px;
  display: flex !important;
  align-items: center;
  gap: 0px;
}

.tree_view_profile_icon i {
  margin-left: 5px;
}

.tree li a img {
  width: 40px;
  height: 40px;
}

.tree li a span {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  color: #fff;
  padding: 8px 4px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}

.tree_view_profile_icon.top_tree {
  width: -moz-fit-content;
  width: fit-content;
  display: flex !important;
  width: 150px;
  margin: 0 auto;
  padding: 15px 10px;
}

.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

/* .tree li a:hover + ul li a span {
  background: #ff0505;
  color: #000;
  border: 1px solid #94a0b400;
} */
.tree-vieww {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drops {
  position: absolute;
  /* height: 100px; */
  width: 200px;
  left: 30px;
  border: 1px solid #414141;
  top: 75%;
  z-index: 1000;
  background-color: #1c1c1c;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.drops p {
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  /* text-align: end; */
}

.kyc_modal_header {
  border: none;
  display: block;
  padding: 20px;
  background: #141414 !important;
  border: 1px solid #761fe3;
}

.kyc_modal_header h6 {
  margin: 20px auto;
  text-align: center;
  color: #fff !important;
  font-size: 16px !important;
}

.kyc_modal_header h6 span {
  color: #761fe3;
  font-size: 18px;
  font-weight: 700;
}

.kyc_modal_header h5 {
  margin: 20px auto;
  text-align: center;
  color: #42ae3c !important;
  font-size: 20px !important;
}

.kyc_modal_header button {
  position: relative;
  font-weight: 500;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
  display: block !important;
  margin: 0 auto;
  border-radius: 12px;
  padding: 14px 24px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: #761fe3;
}

.drops p span {
  font-size: 12px !important;
  /* text-align: start !important; */
  color: #bdbdbd;
  font-weight: 500;
}

.relative {
  position: relative !important;
}

.tree_view_main {
  padding: 0;
  margin-bottom: 100px;
}

.binary_tree_title {
  font-size: 30px !important;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff !important;
}

p.titlr_lable_dd {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.name_tree {
  color: #fff !important;
}

.button_home {
  color: #fff !important;
}

/*  */

.titlr_lable_dd.text-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 50px;
}

.your_pay_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.your_pay_text h4 {
  font-size: 16px !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.your_pay_text p {
  color: #797979 !important;
  font-size: 14px !important;
}

.your_pay_amt_text {
  display: flex;
  justify-content: space-between;
}

.your_pay_amt_text h3 {
  color: #fff !important;
  font-size: 22px !important;
}

.your_pay_amt_text .ui.inline.dropdown {
  padding: 4px 8px;
  border-radius: 30px;
  font-size: 12px;
  height: 40px;
}

.your_pay_amt_text .ui.inline.dropdown > .text {
  font-size: 14px !important;
  margin-right: 10px;
  text-wrap: nowrap;
}

.qrcodes {
  display: block;
  margin: 20px auto;
  background-color: #fff !important;
  width: 150px;
  padding: 10px;
  border-radius: 15px;
}

.your_pay_amt_text .ui.visible.inline.dropdown {
  width: auto !important;
}

.swap_pay_card {
  background: #202020;
  padding: 8px 20px;
  position: relative;
  top: 10px;
  border-radius: 12px;
}

.swap_icon_img img {
  width: 50px;
  display: flex;
  margin: 0 auto;
  position: relative;
  z-index: 6;
}

.swap_price_text p {
  color: #a1a1a1 !important;
  font-size: 14px !important;
}

.swap_price_text {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 8px;
}

.swap_pay_card.swap_pay_card2 {
  margin-top: -25px;
}

.titlr_lable_dd.text-start small {
  font-size: 100% !important;
  color: #9747ff !important;
}

.profile_icon,
.notification_icon {
  width: 24px;
}

.inner_header_buttons {
  gap: 18px;
}

/* .titlr_lable_dd:hover .button_home {
  color: #9747ff !important;
} */
.staking_main {
  padding-bottom: 80px;
}

.staking_banner_section {
  background: url("../src/assets/images/dash_icon/staking_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  position: relative;
  z-index: 4;
}

.staking_banner_content h1 {
  font-size: 38px !important;
  line-height: 46px !important;
  color: #fff !important;
  font-weight: 600 !important;
}

.start_now_button {
  background-color: #ffffff;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px !important;
  color: #0f0f0f !important;
  font-weight: 600 !important;
}

.staking_banner_content {
  padding-top: 80px !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
  z-index: 1 !important;
  border-bottom: 1px solid #fff6;
}

.MuiBox-root.css-19kzrtu
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
  border: none;
}

.essential_premium_section ul#pills-tab {
  display: flex;
  border: 1px solid #761fe3;
  width: fit-content;
  padding: 4px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  border-radius: 8px;
}

.nav-pills .nav-link {
  color: #fff;
}

.nav-link.active,
.nav-pills .show > .nav-link {
  background: #761fe3 !important;
}

.essential_premium_section {
  padding: 46px 45px 46px 46px;
  border-radius: 12px;
  background-color: #1c1c1c;
  margin-top: -60px;
  position: relative;
  z-index: 6;
}

.package-1 h3 {
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  padding-bottom: 15px !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.package-1 {
  padding: 34px 32px 34.2px;
  border-radius: 12px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #1c1c1c;
  position: relative;
  z-index: 10;
}

.package-1 h2 {
  font-size: 48px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

.package-1 h2 span {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.starter_stake_button {
  padding: 16px 0;
  border-radius: 8px;
  border: solid 1px #761fe3;
  background: transparent;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #fff !important;
  width: 100%;
}

.package-1 p {
  color: #a3a3a3 !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-top: 20px;
}

.package-1 i {
  color: #761fe3 !important;
  font-size: 20px !important;
  margin-right: 5px;
  font-weight: 600 !important;
}

.pro_package_list {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  gap: 20px;
}

.staking_tabs_bg img {
  position: absolute;
  top: 40%;
  z-index: 0;
  width: 80% !important;
}

.binary_tree_section {
  background: url("../src//assets/images/dash_icon/tree_view_bg.png");
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.dashborade_content {
  padding: 46px 45px 46px 46px;
  border-radius: 12px;
  background-color: #1c1c1c;
  margin-top: -60px;
  position: relative;
  z-index: 5;
}

.tree_view_profile_icon p {
  color: #fff !important;
}

.settings_main {
  padding: 0;
  padding-top: 40px;
}

.setting_tab_section .nav-link.active,
.setting_tab_section .nav-link {
  /* width: 33.33%; */
  border: none !important;
  padding: 16px !important;
}

.setting_tab_section .nav-link.active {
  padding: 10px;
  border-bottom: solid 2px rgba(151, 71, 255, 0.5) !important;
  background-color: #20202000 !important;
  color: #9747ff !important;
}

.setting_tab_section .nav-link {
  border-bottom: 1px solid #2c2c2c !important;
  color: #fff !important;
}

.settings_password_tabs {
  margin: 0 auto;
  padding: 0 0 20px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #141414;
  margin-bottom: 80px;
  overflow: hidden;
}

.setting_tab_section .nav.nav-tabs {
  border-bottom: 1px solid #2b2b2b !important;
  margin-bottom: 20px;
}
.change_password_content h3 {
  color: #fff !important;
  font-size: 14px !important;
  margin-top: 0;
}

.change_password_content {
  padding: 20px 40px;
}

.change_password_content input {
  padding: 14px 20px;
  border-radius: 8px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #202020;
  width: 100%;
  color: #797979 !important;
  margin-bottom: 10px;
}

.password_sumbit_button {
  border-radius: 12px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #761fe3;
  padding: 18px 20px;
  width: 100%;
  color: #fff;
  margin-top: 15px;
}

.change_password_content h3 {
  color: #d0d0d0 !important;
  font-size: 14px !important;
  margin-bottom: 0;
}

.forgot_password_text {
  color: #9747ff !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.change_password_content h2 {
  color: #ffffff !important;
  font-size: 24px !important;
  margin-bottom: 0;
  margin-top: 20px;
}

.change_password_content p {
  color: #d0d0d0 !important;
}

.landing_responsive_img {
  display: none;
}

.logo-img-1 {
  width: 200px;
  height: auto;
}

.landing-header2 {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 25px;
  padding: 8px 35px;
  border-radius: 8px;
  background-color: rgba(var(--white-color-rgb), 0.05);
  border: 1px solid rgba(var(--white-color-rgb), 0.06);
  width: fit-content;
  /* margin: 0 auto; */
}

.landing-header2 a {
  color: #bdbdbd;
  font-size: 16px;
}

.terms_condition a {
  color: #9747ff;
}

.newacc span {
  color: #9747ff;
}

.landing-header2 a:hover {
  color: #9747ff;
}

.deposit_list {
  display: flex;
  gap: 20px;
}

.deposit_list a {
  color: #fff;
}

.deposit_button {
  font-weight: 600;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  padding: 6px 20px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

.deposit_button img {
  width: 20px;
  margin-right: 10px;
}

.header-right-menu.deposit_list {
  display: flex;
  align-items: center;
}

.css-hyum1k-MuiToolbar-root {
  padding-right: 40px !important;
}

.landing-header1.none_img {
  width: 180px;
  display: flex;
}

.css-d1iqru-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
  background: #121418 !important;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.p-0.css-1nqqqbg-MuiButtonBase-root-MuiIconButton-root {
  margin-top: 12px !important;
}

.header-res-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
  padding: 0 10px;
  padding-right: 40px;
}

.slider-one .swiper-slide {
  position: relative;
  z-index: 1;
}

.drawer_list_deposit {
  font-weight: 600;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  padding: 6px 20px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

.land_header_leftmenus img {
  width: 18px;
  margin-right: 12px;
}

.drawer_list_item {
  color: #fff;
}

.drawer_list_login {
  border: 1px solid #761fe3;
  color: #fff;
  padding: 6px 30px;
  border-radius: 8px;
}

.css-rtpxwx-MuiTypography-root {
  border-bottom: 1px solid #434343 !important;
  padding-bottom: 12px !important;
}

.empty_data {
  background: #141414 !important;
  color: #fff !important;
  text-align: center;
  padding-top: 25px !important;
}

.deposit_history_content a {
  color: #9747ff;
}

.css-k008qs {
  height: 50px !important;
}

.outer_box_2 {
  display: flex;
  gap: 15px;
}

.land_header_leftmenus img {
  width: 22px;
}

.deposit_button a {
  color: #fff;
}

.your_pay_amt_text input {
  width: 80%;
}

.css-1nmdiq5-menu,
.country-list {
  background: #000 !important;
  color: #fff;
}

.country-list li:hover {
  background: #761fe3 !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #761fe3 !important;
}

.errorcss.terms_req {
  margin-top: -20px;
  margin-left: 25px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination.navigation ul li {
  color: #fff !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
  color: #fff;
}

.top_padding {
  padding-top: 60px;
}

/* @media(max-width:1450px){ */
.slider-one .swiper-slide {
  padding: 80px 0px 120px;
}

.slider-one_image-column {
  z-index: 100 !important;
  position: relative;
  margin-top: -20px;
}

.wxdl_dashboard.table tbody tr th,
.wxdl_dashboard.table tbody tr td {
  border: none !important;
}

.wxdl_dashboard.table tbody tr,
.wxdl_dashboard.table tbody tr {
  border-bottom: 1px solid #2b2b2b !important;
}

.background_trans {
  background: transparent !important;
  border: none !important;
}

.wxdl_dashboard.table thead {
  border: none !important;
}

.h1s img {
  width: 60px;
}

@media (max-width: 1199px) {
  .landing_responsive_img {
    display: inline;
    width: 200px;
  }

  .header-right-menu {
    position: absolute;
    right: 0;
  }

  .drawer-head {
    display: flex;
    align-items: baseline;
  }

  /* .header-right-menu a {
    display: none;
  } */
  .header-right-menu.deposit_list {
    display: flex;
    align-items: center;
    padding-right: 50px;
  }

  .header_new_main {
    display: flex;
    align-items: center;
  }

  .deposit_main {
    padding-top: 0px;
  }
}

@media (max-width: 1024px) {
  .left_referral_link_dash h3 {
    margin-bottom: 0;
  }

  .dashboard_links {
    flex-direction: column;
    padding: 4px 20px 10px 20px;
  }

  .left_referral_link input {
    width: 100%;
  }

  .left_referral_link_dash h3 {
    text-align: start;
  }

  .main-header.header-style-one .header-lower .main-menu .navigation {
    padding: 0px 14px;
  }

  .binary_tree_section {
    height: 40vh;
  }
}

@media (max-width: 991px) {
  .main-header_buttons {
    display: inline-flex;
  }

  .dashboard_content_section {
    padding-top: 0;
    margin-top: 0;
  }

  .deposit_main {
    padding-top: 0;
  }

  .titlr_lable_dd.text-start {
    margin-bottom: 20px;
  }

  .css-hyum1k-MuiToolbar-root {
    min-height: 80px !important;
  }

  .your_pay_amt_text .ui.inline.dropdown .menu {
    position: absolute;
    left: -70px;
  }
}

@media (max-width: 840px) {
  .total_dash_card {
    display: flex;
    flex-direction: column;
  }

  .dashboard_content_section {
    padding-top: 0;
    margin-top: 0;
  }

  .package-1 {
    margin-top: 25px;
  }

  .pro_package_list {
    margin-top: 0;
    gap: 0;
    flex-direction: column;
  }

  .wxdl_deposit {
    margin-top: 20px;
  }
}

@media (max-width: 780px) {
  .footer-newsletter_text br {
    display: none;
  }

  .ranking h4,
  .ranking h5 {
    margin: 0px !important;
    font-size: 18px !important;
  }
  .ranking .total_dash_card1 {
    padding: 10px !important;
    min-height: 175px;
  }
  .ranking .total_dash_card1 span {
    font-size: 10px !important;
  }
}
@media (max-width: 540px) {
  .ranking h4,
  .ranking h5 {
    margin: 0px !important;
    font-size: 16px !important;
  }

  .net_address_qr.remainder_content img {
    width: 120px;
  }

  .main-header .header-lower .logo-box .logo {
    width: 160px;
    height: auto;
  }

  .total_dash_content p span {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 600;
  }

  .total_dash_content h4 {
    font-size: 12px !important;
    margin-bottom: 10px;
    text-align: center;
    line-height: 20px !important;
  }

  .total_dash_content p {
    color: #fff !important;
    font-size: 10px !important;
    text-align: center;
  }

  .dashboard_links {
    flex-direction: column;
  }

  .total_dash_card {
    padding: 12px;
    display: flex;
    align-items: center;
    min-height: 110px !important;
    justify-content: space-evenly;
    gap: 0px;
  }

  .left_referral_link input {
    width: 100%;
  }

  .dashboard_content_section {
    padding-top: 0;
    padding-bottom: 40px;
    margin-top: 0;
  }

  .left_referral_link_dash h3 {
    font-size: 15px !important;
    text-align: start;
    margin: 8px 0;
  }

  .deposit_btn button {
    display: none;
  }

  .deposit_main {
    padding-top: 20px;
  }

  p.titlr_lable_dd {
    font-size: 14px !important;
  }

  .binary_tree_title {
    font-size: 24px !important;
  }

  .tree_view_main {
    padding: 0;
    margin-bottom: 100px;
  }

  .swap_main {
    padding: 0;
  }

  .titlr_lable_dd.text-start {
    margin-top: 0 !important;
    padding-top: 20px;
    padding-top: 0 !important;
  }

  .pro_package_list {
    flex-direction: column;
  }

  .package-1 {
    margin-top: 40px;
  }

  .staking_tabs_bg img {
    display: none;
  }

  .pro_package_list {
    margin-top: 0;
  }

  .staking_main {
    padding: 0;
    padding-bottom: 20px;
  }

  .staking_banner_content h1 br {
    display: none;
  }

  .essential_premium_section {
    margin-top: -30px;
  }

  .staking_banner_section {
    margin-top: -20px;
    height: 40vh;
  }

  .staking_banner_content h1 {
    font-size: 20px !important;
    margin: 0px;
  }

  .start_now_button {
    font-size: 15px !important;
  }

  .package-1 h3 {
    font-size: 18px !important;
  }

  .package-1 h2 {
    font-size: 42px !important;
  }

  .package-1 h2 span {
    font-size: 16px !important;
  }

  .package-1 p {
    font-size: 16px !important;
  }

  .starter_stake_button {
    font-size: 15px !important;
  }

  .header-style-one {
    max-height: 60px;
  }

  .settings_main {
    padding: 0;
    padding-top: 20px;
  }

  .change_password_content p {
    font-size: 14px !important;
    margin-top: 20px;
  }

  .dashborade_content {
    margin-top: -60px;
    padding: 0px;
  }
  .tree li {
    padding: 25px 0px 0px;
  }

  .staking_banner_content {
    padding-top: 30px !important;
  }

  .settings_main .titlr_lable_dd.text-start {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .header-style-one {
    min-height: 65px;
    max-height: 65px;
  }

  .staking_banner_section.binary_tree_section {
    height: 25vh;
  }

  .tree_view_profile_icon.top_tree {
    width: auto;
  }

  .tree li a {
    width: 100%;
  }

  .tree_view_profile_icon p {
    text-wrap: nowrap;
    font-size: 10px !important;
  }

  .wxdl_deposit {
    width: 95%;
  }
  .tree-node img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .staking_banner_content input {
    /* width: 100%; */
    color: #fff;
    padding: 10px;
    width: 140px;
    height: 36px;
    border-radius: 6px;
    border: dashed 0.5px rgb(135, 128, 128);
    background-color: #141414 !important;
  }
  .deposit_button1 {
    width: 60px;
    font-size: 10px !important;
    padding: 12px 10px !important;
  }
  .css-hyum1k-MuiToolbar-root {
    min-height: 50px !important;
  }

  .landing_responsive_img {
    width: 160px;
  }

  .css-k008qs {
    height: 50px !important;
  }

  .slider-one .swiper-slide {
    padding: 60px 0px 380px;
    padding-top: 0;
  }

  .wxdl_deposit {
    margin-top: 0;
  }

  .slider-one .slider-one_heading {
    font-size: 30px !important;
    font-weight: 600 !important;
  }

  .sec-title_title {
    font-size: 24px;
  }

  .sec-title_heading {
    font-size: 26px !important;
  }

  .slider-one::before {
    display: none;
  }

  .btn-style-one {
    font-size: 14px;
    padding: 10px 16px;
    width: 100%;
  }

  .footer-title {
    padding-top: 20px;
  }

  .steps-one {
    padding: 0px 0px 20px;
  }

  .footer-social_box a {
    margin-left: 0px;
    margin-right: 12px;
  }

  .template-btn.btn-style-one.btn_width {
    width: fit-content;
  }

  .circle-layer {
    display: none;
  }

  .dots-layer {
    display: none;
  }

  .total_dash_card.h1s {
    background-position: center;
    align-items: center;
    min-height: 120px !important;
  }

  .total_dash_card .d500 {
    width: 30px !important;
    margin-bottom: 10px;
    margin: 0px auto;
    display: block;
  }
  .h1s img {
    width: 40px;
    margin-bottom: 10px;
  }

  .username {
    font-weight: 400;
    font-size: 16px !important;
    padding: 12px;
    margin-bottom: 20px;
  }

  .padding_inner {
    padding: 0 20px !important;
    padding-right: 20px !important;
  }

  .change_password_content {
    padding: 20px 20px;
  }
  .wxdl_dashboard.table tbody tr th,
  .wxdl_dashboard.table tbody tr td {
    padding: 10px;
    font-size: 10px;
  }
  .historytabs a {
    font-size: 12px;
    margin-right: 15px;
  }
  .dashboard_table_content {
    padding: 12px;
  }
  .historytabs a.active {
    font-size: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(118, 31, 227);
    margin-right: 15px;
  }
}

@media (max-width: 480px) {
  .your_pay_amt_text .ui.inline.dropdown {
    width: 50%;
  }

  .your_pay_amt_text .ui.visible.inline.dropdown {
    width: 50% !important;
  }

  .ranking h4,
  .ranking h5 {
    margin: 0px !important;
    font-size: 16px !important;
  }

  .ranking h5 {
    margin-bottom: 15px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 420px) {
  .footer-newsletter_text {
    font-size: 14px;
  }

  .reg_right_main {
    padding: 15px;
  }
}

@media (max-width: 380px) {
  .staking_banner_section {
    height: 50vh;
  }

  .header-style-one {
    min-height: 50px;
  }

  .your_pay_amt_text .ui.inline.dropdown {
    padding: 0px 5px;
  }

  .your_pay_amt_text .ui.inline.dropdown {
    width: 100%;
  }

  .your_pay_amt_text .ui.visible.inline.dropdown {
    width: 100% !important;
  }

  .total_dash_content p span {
    color: #fff !important;
    font-size: 12px !important;
    font-weight: 600;
  }
}

@media (max-width: 350px) {
  .main-header .header-lower .logo-box .logo {
    width: 150px;
    height: auto;
  }
}

h6.MuiTypography-root.MuiTypography-h6.css-1f6my4d {
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.css-hyum1k-MuiToolbar-root,
.css-i6s8oy {
  background: #141414 !important;
}

.css-i6s8oy {
  padding: 0 !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-hyum1k-MuiToolbar-root {
  padding: 0 !important;
}

/* Remove the default Google Chrome autofill background color */
input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #202020 inset !important;
  -webkit-text-fill-color: #fff !important;
  border: none !important;
}

.text,
p {
  color: #fff !important;
}

.title,
.cafe {
  position: relative;
  z-index: 10;
  margin: 40px 0;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.mt-2.css-i4bv87-MuiSvgIcon-root {
  font-size: 30px;
}

h4.h1s {
  font-size: 30px !important;
}

.slider-one::before {
  display: none;
}

.modalss .modal-content {
  background-color: #0000 !important;
  border: none !important;
  margin-top: 25%;
}

.barder-theme {
  border: 1px solid #8905f0 !important;
}

.witthdraw_amt_title.text-danger {
  color: #ff7373 !important;
}

.witthdraw_amt_title.text-success {
  color: #6ad16a !important;
}

.css-q4e360-control,
.css-1xepz10-control {
  height: 50px !important;
  border-radius: 6px;
  border: solid 0.5px rgba(255, 255, 255, 0.1) !important;
  background-color: #141414 !important;
}

.css-prdzvo-option {
  color: #fff !important;
}

.css-1dimb5e-singleValue {
  color: #6f6f6f !important;
}

.deposit_button1 {
  font-weight: 600;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  padding: 16px 20px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

.text-red {
  color: red !important;
}
