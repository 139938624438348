.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.reg_right_main {
  /* width: 524px;
  height: 973px; */
  padding: 40px;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #0f0f0f !important;
}

.reg_right_main .reg_left_head {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.48px;
  color: #fff;
}

.change_password_content.reg_right_main {
  background-color: #141414 !important;
  border: solid 1px #141414 !important;
}

.reg_right_main .p,
.newacc {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
}

.terms_condition_div {
  display: flex;
  margin: 20px 0px !important;
  gap: 10px !important;
}

.reg_right_main .earn_divs_insubhead {
  font-size: 14px !important;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8) !important;
  margin: 8px 0px 0px 0px !important;
}

.reg_right_main .reg_inp_field {
  margin: 8px 0px !important;
  width: 100%;
  color: #fff;
  padding: 16px;
  border-radius: 6px;
  border: solid 0.5px rgba(255, 255, 255, 0.1);
  background-color: #141414 !important;
}
.flex_input_posion {
  position: relative;
}
.flex_input_posion i {
  position: absolute;
  top: 30px;
  color: #fff;
  right: 20px;
}

.req_field_sin .react-tel-input .form-control {
  position: relative;
  height: 50px !important;
  padding: 16px;
  box-shadow: none !important;
  background-color: #141414 !important;
  border-radius: 6px;
  border: solid 1px rgb(44 44 44) !important;

  color: #fff;
  padding-left: 48px;
  margin-left: 0;
}

.react-tel-input .selected-flag {
  outline: none !important;
  background-color: #141414 !important;
  border: solid 0.5px rgba(255, 255, 255, 0.1) !important;
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown {
  border: solid 0.5px rgba(255, 255, 255, 0.1) !important;
}

.react-tel-input .flag-dropdown {
  border: solid 0.5px rgb(44 44 44) !important;
}
.react-tel-input .flag-dropdown {
  border: solid 0.5px rgb(44 44 44) !important;
}
.react-tel-input .selected-flag {
  border: 3px 0px 0px 3px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  margin: 8px 0px !important;
  width: 100%;
  color: #fff;
  padding: 5px 0px !important;
  border-radius: 6px;
  outline: none !important;
  border: solid 0.5px rgba(255, 255, 255, 0.1) !important;
  background-color: #141414 !important;
}
.errorcss {
  color: #ff7373 !important;
  font-size: 14px !important;
  /* margin-left: 15px; */
}

.svg-container svg {
  width: 100% !important;
}

.net_address_qr.remainder_content img {
  width: 200px;
  border-radius: 20px;
}
.change_password_content .reg_inp_field {
  position: relative !important;
}
.flex_input_posion .pass-hide-icon {
  position: absolute;
  top: 0 !important;
}

.change_password_content .flex_input_posion i {
  position: absolute;
  top: 15px;
  color: #fff;
  right: 20px;
}
.change_password_content .flex_input_posion i.t25 {
  top: 25px !important;
}
.tree-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tree-node {
  position: relative;
  text-align: center;
}

.tree-node img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.tree-node .node-info {
  margin-top: 5px;
}

.tree-children {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tree-container.left .tree-item {
  display: flex;
  justify-content: flex-start;
}

.tree-container.right .tree-item {
  display: flex;
  justify-content: flex-end;
}

.tree-line {
  height: 20px;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  margin: 0 10px;
}

.tree-line-horizontal {
  height: 1px;
  width: 100%;
  background-color: #000;
}
.reg_right_main .p {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.css-1q8lr0k .MuiDrawer-paper {
  background: #121418 !important;
}
.header_res_flx svg {
  font-size: 38px;
  padding-top: 5px;
}
.p2p_table_content {
  padding-bottom: 80px !important;
}

.historytabs a, .historytabs a:hover {
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
}

.historytabs a.active {
  color: rgb(118, 31, 227);
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(118, 31, 227);
  margin-right: 20px;
}


.nav_link {
  cursor: pointer;
}

.withdraw_select_address
{
  display: inline-block; /* Ensure inline alignment */
  max-width: 100%; /* Prevent overflow beyond container */
  white-space: nowrap; /* Stop text wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis */
}

.plus_14_ff {
  cursor: pointer;
}


.rank-container {
  display: flex;
  justify-content: space-around; /* Distribute ranks evenly in the row */
  flex-wrap: nowrap; /* Ensure all ranks stay on the same row */
  gap: 20px; /* Space between each rank */
}

.rank {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rank-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px; /* Space between name and logo */
}

.rank-logo {
  width: 50px; /* Set desired width */
  height: 50px; /* Set desired height */
  object-fit: cover; /* Ensure image is not distorted */
}

  /* .col-3 {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .ranking {
    background: #000;
    color: #fff;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    max-width: 320px;
    width: 90%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .ranking h4,
  .ranking h5 {
    margin: 5px 0;
    font-size: 1rem;
    word-wrap: break-word;
  }

  .ranking h5 {
    color: #ccc;
  }

  .total_dash_card1 p {
    margin: 10px 0;
    font-size: 0.9rem;
    color: #00aaff;
    white-space: normal;
    word-wrap: break-word;
  }

  .total_dash_card1 img {
    width: 100%;
    max-width: 200
  } */

  .chart-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    flex-wrap: wrap; /* Allow wrapping for small screens */
  }
  
  .chart-section {
    flex: 1; /* Allow flexible resizing */
    min-width: 150px; /* Prevent the chart from shrinking too much */
  }
  
  .text-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    min-width: 150px; /* Prevent the text section from shrinking too much */
    font-size: 18px;
    font-weight: 600;
  }

  .total-earnings {
    color: #6be7d8
  }

  .balance {
    color: #a64ac9
  }
  
  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .chart-container {
      flex-direction: column; /* Stack chart and text vertically */
      align-items: center; /* Center-align items */
      gap: 15px; /* Adjust gap for smaller screens */
    }
  
    .chart-section,
    .text-section {
      width: 100%; /* Use full width for each section */
      text-align: center; /* Center-align text for mobile */
    }
  
    .text-line {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .total-earnings span,
    .balance span,
    .current-package span {
      font-size: 18px; /* Slightly smaller font for highlighted text */
      font-weight: 600;
    }
  }
  
