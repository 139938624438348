/* Braine Digital Agency Business HTML-5 Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Two / Three
5. Slider One / Two
6. Services One
7. About One
8. Choose One / Two
9. Answer One
10. Testimonial One / Two / Three
11. Steps One
12. Price One / Two
13. Faq One / Two
14. Social One
15. News One / Two
16. CTA One / Two / Three / Four
17. Main Footer
18. Banner One
19. Assistance One
20. LiveChat One
21. Counter One
22. Conversation One
23. Clients One
24. Order One
25. Tools One
26. Price Two
27. Community One
28. Members One
29. Data One
30. Page Title
31. Story One
32. Value One
33. Team One
34. Login
35. Register
36. Forgot Password
37. Team Detail
38. Service Detail
39. Our Blog
40. Blog Widgets
41. Blog Classic
42. Blog Detail
43. Comments Form
44. Comments Box
45. Not Found
46. Contact Info
47. Contact Form
48. Map

**********************************************/

/* 

	font-family: 'Inter', sans-serif;

*/

/*** 

====================================================================
	Root Code Variables
====================================================================

 ***/

.theme_color {
  color: var(--main-color) !important;
}

/* Theme Color */

:root {
  /* #761FE3 in decimal RGB */
  --main-color: rgb(118, 31, 227) !important;
  --main-color-rgb: 118, 31, 227 !important;

  /* #141414 in decimal RGB */
  --color-two: rgb(20, 20, 20) !important;
  --color-two-rgb: 20, 20, 20 !important;

  /* #BDBDBD in decimal RGB */
  --color-three: rgb(189, 189, 189) !important;
  --color-three-rgb: 189, 189, 189 !important;

  /* #0F0F0F in decimal RGB */
  --color-four: rgb(15, 15, 15) !important;
  --color-four-rgb: 15, 15, 15 !important;

  /* #D093E8 in decimal RGB */
  --color-five: rgb(208, 147, 232) !important;
  --color-five-rgb: 208, 147, 232 !important ;

  /* #A3A3A3 in decimal RGB */
  --color-six: rgb(163, 163, 163) !important;
  --color-six-rgb: 163, 163, 163 !important;

  /* #1C1C1C in decimal RGB */
  --color-seven: rgb(28, 28, 28) !important;
  --color-seven-rgb: 28, 28, 28 !important;

  /* #333333 in decimal RGB */
  --color-eight: rgb(51, 51, 51) !important;
  --color-eight-rgb: 51, 51, 51 !important;

  /* #242424 in decimal RGB */
  --color-nine: rgb(36, 36, 36) !important;
  --color-nine-rgb: 36, 36, 36 !important;

  /* #343334 in decimal RGB */
  --color-ten: rgb(52, 51, 52) !important;
  --color-ten-rgb: 52, 51, 52 !important;

  /* #ffffff in decimal RGB */
  --white-color: rgb(255, 255, 255) !important;
  --white-color-rgb: 255, 255, 255 !important;

  /* #000000 in decimal RGB */
  --black-color: rgb(0, 0, 0) !important;
  --black-color-rgb: 0, 0, 0 !important ;
}

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

***/

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--main-color) !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-three) !important;
}

body {
  font-family: "Inter", sans-serif;
  line-height: 1.6em;
  font-weight: 400;
  font-size: 14px;

  background-color: var(--color-two) !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.dark-layout {
  position: relative;
  background-color: var(--black-color);
}

/* Preloader */

.handle-preloader {
  background-color: var(--main-color);
}

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader-close {
  position: fixed;
  z-index: 9999999999999;
  font-size: 18px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  right: 15px;
  top: 15px;
  border-radius: 0%;
  display: none;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  text-transform: capitalize;
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
  font-family: "Inter", sans-serif;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  font-family: "Inter", sans-serif;
  color: transparent;
  -webkit-text-stroke-width: 1px;

  -webkit-text-stroke-color: rgba(255, 255, 255, 0.3);
}

.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.handle-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.handle-preloader .loader-section {
  background-color: #fff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  color: #fff;
}

.handle-preloader .animation-preloader .spinner {
  border: 3px solid #fff;
  border-top-color: rgba(255, 255, 255, 0.5);
}

/* AnimaciÃƒÆ’Ã†â€™Ãƒâ€ Ã¢â‚¬â„¢ÃƒÆ’Ã¢â‚¬ ÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢ÃƒÆ’Ã†â€™ÃƒÂ¢Ã¢â€šÂ¬Ã…Â¡ÃƒÆ’Ã¢â‚¬Å¡Ãƒâ€šÃ‚Â³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 30px;
    letter-spacing: 10px;
  }
}

i {
  font-style: normal;
}

.bordered-layout .page-wrapper {
  padding: 0px 50px 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  color: var(--main-color);
}

button,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin: 0px;
  background: none;
  color: var(--color-four) !important;
  font-family: "Inter", sans-serif !important;
}

input,
button,
select,
textarea {
}

textarea {
  overflow: hidden;
}

.text,
p {
  position: relative;
  line-height: 1.7em;
  color: var(--color-four);
  font-size: 16px !important;
}

/* Typography */

h1 {
  line-height: 60px !important;
  font-size: 68px !important;
  font-weight: 600 !important;
}

h2 {
  font-weight: 600 !important;
  line-height: 58px !important;
  font-size: 40px !important;
}

h3 {
  line-height: 42px !important;
  font-size: 32px !important;
}

h4 {
  line-height: 38px !important;
  font-size: 28px !important;
}

h5 {
  line-height: 32px !important;
  font-size: 22px !important;
}

h6 {
  line-height: 28px !important;
  font-size: 18px !important;
}

.auto-container {
  position: static;
  max-width: 1350px;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

.template-btn {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.centered {
  text-align: center;
}

::-webkit-input-placeholder {
  color: inherit;
}
::-moz-input-placeholder {
  color: inherit;
}
::-ms-input-placeholder {
  color: inherit;
}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

/* backtoup */
.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 40px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translateY(15px);
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
}
.progress-wrap::after {
  position: absolute;
  content: "\f176";
  font-family: "Font Awesome 5 Free";
  text-align: center;
  line-height: 35px;
  font-size: 15px;
  color: var(--main-color);
  left: 0;
  top: 0;
  height: 35px;
  width: 35px;
  cursor: pointer;
  display: block;
  font-weight: 700;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  font-size: 13px;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: var(--main-color);
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress-wrap.style2::after {
  color: var(--thm-color-2);
}
.progress-wrap.style2 svg.progress-circle path {
  stroke: var(--thm-color-2);
}
.progress-wrap.style3::after {
  color: var(--thm-color-3);
}
.progress-wrap.style3 svg.progress-circle path {
  stroke: var(--thm-color-3);
}

/* List Style One */

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  color: var(--white-color) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
  margin-bottom: 10px;
  padding-left: 30px;
}

.list-style-one li a {
  position: relative;
  color: var(--white-color) !important;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.list-style-one li a:hover {
  color: var(--main-color);
}

.list-style-one li .icon {
  position: absolute;
  left: 0px;
  top: 5px;
  color: var(--main-color);
  font-size: 18px;
  line-height: 1em;
  font-weight: 300;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

/* Btn Style One */

.btn-style-one {
  position: relative;
  font-weight: 600;
  font-size: 15px;
  overflow: hidden;
  text-align: center;
  border-radius: 12px;
  padding: 14px 24px;
  display: inline-block;
  color: var(--white-color) !important;
  background-color: var(--main-color);
}

.btn-style-one:before {
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: -200%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 1;
  background-color: var(--white-color) !important;
}

.btn-style-one:hover:before {
  top: 0%;
}

.btn-style-one .btn-wrap {
  position: relative;
  z-index: 1;
  float: left;
  overflow: hidden;
  display: inline-block;
}

.btn-style-one .btn-wrap .text-one {
  position: relative;
  display: block;
  color: var(--white-color) !important;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.btn-style-one:hover .btn-wrap .text-one:first-child {
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
}

.btn-style-one .btn-wrap .text-two {
  position: absolute;
  top: 100%;
  display: block;
  color: var(--main-color);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-style-one:hover .btn-wrap .text-two {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-style-one:hover .btn-wrap .text-two {
}

.btn-style-one:hover {
}

.btn-style-one:hover:before {
  top: -40%;
}

/* Btn Style Two */

.btn-style-two {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  text-align: center;
  border-radius: 12px;
  padding: 12px 24px;
  display: inline-block;
  color: var(--white-color) !important;
  border: 1px solid var(--main-color);
}

.btn-style-two:before {
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: -200%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 1;
  background-color: var(--main-color);
}

.btn-style-two:hover:before {
  top: 0%;
}

.btn-style-two .btn-wrap {
  position: relative;
  z-index: 1;
  float: left;
  overflow: hidden;
  display: inline-block;
}

.btn-style-two .btn-wrap .text-one {
  position: relative;
  display: block;
  color: var(--white-color) !important;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.btn-style-two:hover .btn-wrap .text-one:first-child {
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
}

.btn-style-two .btn-wrap .text-two {
  position: absolute;
  top: 100%;
  display: block;
  color: var(--white-color) !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-style-two:hover .btn-wrap .text-two {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-style-two:hover .btn-wrap .text-two {
}

.btn-style-two:hover {
}

.btn-style-two:hover:before {
  top: -40%;
}

/* Btn Style Three */

.btn-style-three {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  overflow: hidden;
  text-align: center;
  border-radius: 8px;
  padding: 18px 34px;
  display: inline-block;
  color: var(--color-four);
  background-color: var(--white-color) !important;
}

.btn-style-three:before {
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  top: -200%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: 1;
  background-color: var(--color-four);
}

.btn-style-three:hover:before {
  top: 0%;
}

.btn-style-three .btn-wrap {
  position: relative;
  z-index: 1;
  float: left;
  overflow: hidden;
  display: inline-block;
}

.btn-style-three .btn-wrap .text-one {
  position: relative;
  display: block;
  color: var(--color-four);
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.btn-style-three:hover .btn-wrap .text-one:first-child {
  -webkit-transform: translateY(-150%);
  -ms-transform: translateY(-150%);
  transform: translateY(-150%);
}

.btn-style-three .btn-wrap .text-two {
  position: absolute;
  top: 100%;
  display: block;
  color: var(--white-color) !important;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.btn-style-three:hover .btn-wrap .text-two {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-style-three:hover .btn-wrap .text-two {
}

.btn-style-three:hover {
}

.btn-style-three:hover:before {
  top: -40%;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}

.sec-title_icon {
  position: relative;
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  display: inline-block;
  margin: 0 auto;
  font-size: 20px;
  border-radius: 50px;
  margin-bottom: 10px;
  color: var(--color-five);
  border-top: 1px solid var(--color-five);
  background-color: rgba(var(--main-color-rgb), 0.2);
}
.sec-title_title {
  position: relative;
  font-weight: 600;
  background: linear-gradient(to right, #761fe3, #d093e8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 38px;
  margin-top: 15px;
  /* text-transform: uppercase; */
}
.sec-title_title.about_us_title{
  text-transform: uppercase !important;
}
.sec-title_heading {
  margin-top: 15px;
  color: var(--white-color) !important;
  font-size: 38px !important;
  font-weight: 600 !important;
}

.sec-title_heading span {
  position: relative;
  font-style: italic;
  display: inline-block;
  font-family: "Lora", serif;
  text-transform: lowercase;
}

.sec-title_heading span::before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 8px;
  right: 0px;
  z-index: -1;
  height: 10px;
  border-radius: 50px;

  background: var(--main-color);
  background: linear-gradient(
    to top,
    var(--main-color) 0%,
    var(--color-two) 100%
  );
}

.sec-title_text {
  line-height: 30px;
  font-size: 17px;
  margin-top: 20px;
  color: var(--color-three);
}

.sec-title.light .sec-title_title {
  color: var(--white-color) !important;
}

.sec-title.light .sec-title_heading span::before {
  opacity: 0.7;
  background: var(--white-color) !important;
  background: linear-gradient(
    to top,
    var(--white-color) 0%,
    var(--main-color) 100%
  );
}

.sec-title.light .sec-title_text {
  opacity: 0.6;
  color: var(--white-color) !important;
}

.sec-title.light .sec-title_heading {
  color: var(--white-color) !important;
}

.sec-title.centered {
  text-align: center !important;
}

.sec-title.centered .separator {
  margin: 0 auto;
}

/* Style Two */

.sec-title.style-two .sec-title_title {
  color: var(--main-color);
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border-radius: 3px;
  text-transform: capitalize;
}

.sec-title.style-two .sec-title_title::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.1;
  border-radius: 5px;
  background: var(--main-color);
  background: linear-gradient(
    to bottom,
    var(--main-color) 0%,
    var(--color-five) 100%
  );
}

.sec-title.style-two .sec-title_heading span {
  color: var(--main-color);
}

.sec-title.style-two .sec-title_heading span::before {
  display: none;
}

.sec-title.style-two.light .sec-title_title {
  color: var(--white-color) !important;
  background-color: rgba(var(--white-color-rgb), 0.1);
}

.sec-title.style-two.light .sec-title_heading span {
  color: var(--white-color) !important;
}

/* Style Three */

.sec-title.style-three .sec-title_title {
  padding: 7px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
  letter-spacing: 0px;
  color: var(--white-color) !important;
  text-transform: capitalize;
}

.sec-title.style-three .sec-title_title::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  border-radius: 5px;
  background: var(--main-color);
  background: linear-gradient(
    to right,
    var(--main-color) 0%,
    var(--color-five) 80%
  );
}

.sec-title.style-three .sec-title_heading span::before {
  display: none;
}

.sec-title.style-three.light .sec-title_title {
  color: var(--white-color) !important;
  background-color: rgba(var(--white-color-rgb), 0.1);
}

.sec-title.style-three .sec-title_heading span {
  color: var(--main-color);
  display: inline-block;
  background: linear-gradient(
    to right,
    var(--main-color) 0%,
    var(--color-five) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sec-title.style-four .sec-title_title {
  padding: 0px 0px;
  color: #d093e8;
  text-transform: uppercase;
}

.sec-title.style-four .sec-title_heading span {
  color: var(--main-color);
  font-style: italic;
  display: inline-block;
  font-family: "Lora", serif;
  text-transform: lowercase;
}

.sec-title.style-four .sec-title_heading span::before {
  display: none;
}

/* Custom Select */

.form-group .ui-selectmenu-button.ui-button {
  top: -2px;
  width: 100%;
  border: 0px;
  padding: 19px 25px;
  padding-left: 65px;
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
  border-radius: 6px;
  color: var(--white-color) !important;
  background: none;
  border: 1px solid rgba(var(--white-color-rgb), 0.08);
}

.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 3px;
  text-indent: 0px;
  color: #a5a5a5;
}

.form-group .ui-button .ui-icon:before {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 0px;
  top: 2px !important;
  top: 10px;
  height: 22px;
  display: block;
  line-height: 20px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  z-index: 5;
  color: var(--color-three);
}

.ui-menu .ui-menu-item {
  font-size: 14px;
}

.ui-menu .ui-menu-item:last-child {
  border: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 8px 20px;
  line-height: 24px;
  font-size: 14px;
}

.ui-menu-item:hover {
  background-color: var(--color-two);
}

/* Cursor */

.cursor {
  position: fixed;
  background-color: var(--main-color);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cursor.active {
  opacity: 1;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cursor.menu-active {
  opacity: 1;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cursor.hovered {
  opacity: 1;
}

.cursor-follower {
  position: fixed;
  border: 0.5px solid var(--main-color);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) background,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  -webkit-transform: translate(2px, 2px);
  transform: translate(2px, 2px);
}

.cursor-follower.active {
  opacity: 1;
  -webkit-transform: scale(2);
  transform: scale(2);
}

.cursor-follower.menu-active {
  opacity: 1;
  -webkit-transform: scale(2);
  transform: scale(2);
}

.cursor-follower.hovered {
  opacity: 1;
}

/* Cursor End */

.xs-sidebar-group .close-button {
  font-family: "Flaticon";
}

.newsletter-popup-area-section {
  display: none;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}
