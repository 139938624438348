@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoond168.eot?kdgjb0');
  src:  url('///fonts/icomoon.eot?kdgjb0#iefix') format('embedded-opentype'),
    url('../fonts/icomoond168.ttf?kdgjb0') format('truetype'),
    url('../fonts/icomoond168.woff?kdgjb0') format('woff'),
    url('../fonts/icomoond168.svg?kdgjb0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e902";
}
.icon-quote-1:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-magnet-1:before {
  content: "\e907";
}
.icon-seo:before {
  content: "\e908";
}
.icon-eye:before {
  content: "\e909";
}
.icon-heart-hand:before {
  content: "\e90a";
}
.icon-bulb1:before {
  content: "\e90b";
}
.icon-users-4:before {
  content: "\e90d";
}
.icon-user-3:before {
  content: "\e90e";
}
.icon-hand-star:before {
  content: "\e90f";
}
.icon-crown:before {
  content: "\e910";
}
.icon-award .path1:before {
  content: "\e911";
  color: rgb(15, 15, 15);
}
.icon-award .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-star:before {
  content: "\e913";
}
.icon-flag-2:before {
  content: "\e914";
}
.icon-quote:before {
  content: "\e915";
}
.icon-thumb:before {
  content: "\e916";
}
.icon-flag:before {
  content: "\e917";
}
.icon-user-2:before {
  content: "\e918";
}
.icon-diagram:before {
  content: "\e919";
}
.icon-magnet:before {
  content: "\e91a";
}
.icon-cart:before {
  content: "\e91b";
}
.icon-tag:before {
  content: "\e91c";
}
.icon-copyright:before {
  content: "\e91d";
}
.icon-users:before {
  content: "\e91e";
}
.icon-marketing:before {
  content: "\e91f";
}
.icon-speaker1:before {
  content: "\e920";
}
.icon-bulb:before {
  content: "\e90c";
}
.icon-speaker:before {
  content: "\e921";
}
